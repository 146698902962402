import { defineStore } from 'pinia';
import type { ICollection, IGame, IGameProvider, IWebSocketResponse } from '~/types';

type MobileModalType = 'depositOrDemo' | 'deposit' | 'registerOrDemo' | 'registerOrLogin';
interface IGamesStoreState {
  gameProvidersPromise: Promise<IGameProvider[]> | null;
  gameCollectionsPromise: Promise<ICollection[]> | null;
  favoriteGames: IGame[];
  betsSubscription: any;
  mobileGameModalType: Maybe<MobileModalType>;
  mobileGameModalInfo: Maybe<IGame>;
  isBonusWagering: boolean;
  minimumBonusWagerMultiplier: number;
  hasRecentlyGames: boolean;
  checkRecentlyPromise: Promise<IGame[]> | null;
  collectionsList: ICollection[];
  favoritesCategory: ICollection;
  recentlyCategory: ICollection;
}

export const useGamesStore = defineStore('gamesStore', {
  state: (): IGamesStoreState => ({
    gameProvidersPromise: null,
    gameCollectionsPromise: null,
    favoriteGames: [],
    betsSubscription: undefined,
    mobileGameModalType: undefined,
    mobileGameModalInfo: undefined,
    isBonusWagering: false,
    minimumBonusWagerMultiplier: 1,
    hasRecentlyGames: false,
    checkRecentlyPromise: null,
    collectionsList: [],
    favoritesCategory: {
      id: 'favorites',
      identity: 'favorites',
      name: 'Favorite',
      countries: [],
      isHidden: false,
    },
    recentlyCategory: {
      id: 'recently',
      identity: 'recently',
      name: 'Last Played',
      countries: [],
      isHidden: false,
    },
  }),

  getters: {
    gameCollections(state): ICollection[] {
      const globalStore = useGlobalStore();
      if (!globalStore.headerCountry) return state.collectionsList;

      return state.collectionsList.filter(collection => {
        return !collection.countries.length || collection.countries.includes(globalStore.headerCountry as string);
      });
    },
  },

  actions: {
    async getProvidersRequest(): Promise<IGameProvider[]> {
      const { getGameProviders } = useCoreGamesApi();

      try {
        return await getGameProviders();
      } catch {
        return [];
      }
    },

    async getProviderList(): Promise<IGameProvider[]> {
      if (!this.gameProvidersPromise) {
        this.gameProvidersPromise = this.getProvidersRequest();
      }
      return this.gameProvidersPromise;
    },

    async getCollections(): Promise<void> {
      const { getGameCollections } = useCoreGamesApi();

      try {
        this.collectionsList = await getGameCollections();
      } catch {
        this.collectionsList = [];
      }
    },

    async getFavoriteGames(): Promise<void> {
      const { getFavorite } = useCoreGamesApi();
      this.favoriteGames = await getFavorite();
    },

    async checkRecentlyGames(): Promise<void> {
      if (this.hasRecentlyGames || this.checkRecentlyPromise) return;

      const { getRecentlyPlayed } = useCoreGamesApi();
      const profileStore = useProfileStore();
      const globalStore = useGlobalStore();
      this.checkRecentlyPromise = getRecentlyPlayed({
        page: 1,
        perPage: 1,
        platform: globalStore.isMobile ? 1 : 2,
        countryCode: profileStore.profile?.country || globalStore.headerCountry || 'UA',
      });
      const responseGames = await this.checkRecentlyPromise;
      this.hasRecentlyGames = !!responseGames.length;
      this.checkRecentlyPromise = null;
    },

    async setFavoriteGame(gameId: string): Promise<void> {
      const { setFavorite } = useCoreGamesApi();
      this.favoriteGames = await setFavorite(gameId);
    },

    async deleteFavoriteGame(gameId: string): Promise<void> {
      const { deleteFavorite } = useCoreGamesApi();
      this.favoriteGames = await deleteFavorite(gameId);
    },

    async openMobileGameModal(modalType: MobileModalType, gameInfo: IGame): Promise<void> {
      this.mobileGameModalType = modalType;
      this.mobileGameModalInfo = gameInfo;
      const { openModal } = useModalStore();
      await openModal('mobile-game');
    },

    defineBonusWagerInfo(isBonusWagering: boolean, minimumBonusWagerMultiplier: number): void {
      this.isBonusWagering = isBonusWagering;
      this.minimumBonusWagerMultiplier = minimumBonusWagerMultiplier;
    },

    handleBetsEvent(socketData: IWebSocketResponse): void {
      const betsEvent = socketData.data.event;
      if (betsEvent === 'game.bet.restricted') useEvent('restrictedBets', socketData.data.gameIdentity as string);
      else if (betsEvent === 'game.bet.max.exceed') {
        const bonusCurrency = socketData.data.playerBonus?.currency;
        const bonusMaxAmount = socketData.data.playerBonus?.maxBetAmount;
        if (!bonusCurrency || !bonusMaxAmount) return;
        const { formatAmount } = useProjectMethods();
        const maxBet = formatAmount(bonusCurrency, bonusMaxAmount);
        useEvent('maxBets', {
          gameIdentity: socketData.data.gameIdentity as string,
          maxBet,
        });
      }
    },

    subscribeBetsSocket(): void {
      const { createSubscription } = useWebSocket();
      const profileStore = useProfileStore();
      this.betsSubscription = createSubscription(`game:bets#${profileStore.profile?.id}`, this.handleBetsEvent);
    },

    unsubscribeBetsSocket(): void {
      if (this.betsSubscription) {
        this.betsSubscription.unsubscribe();
        this.betsSubscription.removeAllListeners();
      }
    },
  },
});
