import mitt from 'mitt';
import type { IAnalyticsEvent, IMessage, ISocketInvoice } from '~/types';

type ApplicationEvents = {
  changeMobileGameMode: undefined;
  accountChanged: undefined;
  restrictedBets: string;
  maxBets: {
    gameIdentity: string;
    maxBet: string;
  };
  analyticsEvent: IAnalyticsEvent;
  completedQuestsUpdated: undefined;
  expiredQuestsUpdated: undefined;
  markAsReadMessage: IMessage;
  newPlayerMessage: undefined;
  markAllAsReadMessages: undefined;
  receivedAsyncInvoice: ISocketInvoice;
  depositInvoiceUpdated: undefined;
  questActivated: undefined;
  webSocketReconnected: undefined;
};

const emitter = mitt<ApplicationEvents>();

export const useEvent = emitter.emit;
export const useListen = emitter.on;
export const useUnlisten = emitter.off;
