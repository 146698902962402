
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93uVjAiQKNcYMeta } from "/builds/platform/customer-frontends/winlion/pages/[...slug].vue?macro=true";
import { default as autologinHamLQPEWF1Meta } from "/builds/platform/customer-frontends/winlion/pages/auth/autologin.vue?macro=true";
import { default as callbackU6H85HSu1tMeta } from "/builds/platform/customer-frontends/winlion/pages/auth/callback.vue?macro=true";
import { default as bonusesuXogU7ECK2Meta } from "/builds/platform/customer-frontends/winlion/pages/bonuses.vue?macro=true";
import { default as _91categoryIdentity_936aP32TLsShMeta } from "/builds/platform/customer-frontends/winlion/pages/casino/[categoryIdentity].vue?macro=true";
import { default as indexiLnN7SO9R3Meta } from "/builds/platform/customer-frontends/winlion/pages/casino/index.vue?macro=true";
import { default as casinosM2CLqjnyLMeta } from "/builds/platform/customer-frontends/winlion/pages/casino.vue?macro=true";
import { default as contactTJaOYk9SyfMeta } from "/builds/platform/customer-frontends/winlion/pages/contact.vue?macro=true";
import { default as favorites_46clientlfal5o7O9bMeta } from "/builds/platform/customer-frontends/winlion/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/winlion/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93DO5g53ZbA3Meta } from "/builds/platform/customer-frontends/winlion/pages/games/[id].vue?macro=true";
import { default as indexx1RWZKiym3Meta } from "/builds/platform/customer-frontends/winlion/pages/index.vue?macro=true";
import { default as _91categoryIdentity_93magRUYya76Meta } from "/builds/platform/customer-frontends/winlion/pages/live-casino/[categoryIdentity].vue?macro=true";
import { default as indexVSps3ZbXu5Meta } from "/builds/platform/customer-frontends/winlion/pages/live-casino/index.vue?macro=true";
import { default as live_45casinoNVgp3RHVNqMeta } from "/builds/platform/customer-frontends/winlion/pages/live-casino.vue?macro=true";
import { default as loyaltyg5z1i32XKzMeta } from "/builds/platform/customer-frontends/winlion/pages/loyalty.vue?macro=true";
import { default as documentsqubakRomQzMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/documents.vue?macro=true";
import { default as historyfCpgmdgPzuMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/history.vue?macro=true";
import { default as infoxxOrpzAtA3Meta } from "/builds/platform/customer-frontends/winlion/pages/profile/info.vue?macro=true";
import { default as limitsok7iGAahNGMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/limits.vue?macro=true";
import { default as notifications17sHJVzNtqMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/notifications.vue?macro=true";
import { default as securitymto7GPahnfMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/security.vue?macro=true";
import { default as verificationtoutRxmhDVMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/verification.vue?macro=true";
import { default as profile_46clientIFkufzhE8pMeta } from "/builds/platform/customer-frontends/winlion/pages/profile.client.vue?macro=true";
import { default as _91pageIdentity_934L8iW18a8kMeta } from "/builds/platform/customer-frontends/winlion/pages/questions/[pageIdentity].vue?macro=true";
import { default as questionsFxA7khpAxNMeta } from "/builds/platform/customer-frontends/winlion/pages/questions.vue?macro=true";
import { default as recently_46clientPRb44d3KbtMeta } from "/builds/platform/customer-frontends/winlion/pages/recently.client.vue?macro=true";
import { default as sign_45up0WGB0WOSBrMeta } from "/builds/platform/customer-frontends/winlion/pages/sign-up.vue?macro=true";
import { default as sports8VafnmxNnyMeta } from "/builds/platform/customer-frontends/winlion/pages/sports.vue?macro=true";
import { default as _91pageIdentity_934wZYYXTiJTMeta } from "/builds/platform/customer-frontends/winlion/pages/static/[pageIdentity].vue?macro=true";
import { default as _91identity_93qO7i2nggkLMeta } from "/builds/platform/customer-frontends/winlion/pages/tournaments/[identity].vue?macro=true";
import { default as indexy4JGTAX5lEMeta } from "/builds/platform/customer-frontends/winlion/pages/tournaments/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/auth/autologin.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/auth/callback.vue")
  },
  {
    name: "bonuses",
    path: "/bonuses",
    meta: bonusesuXogU7ECK2Meta || {},
    component: () => import("/builds/platform/customer-frontends/winlion/pages/bonuses.vue")
  },
  {
    name: casinosM2CLqjnyLMeta?.name,
    path: "/casino",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/casino.vue"),
    children: [
  {
    name: "casino-categoryIdentity",
    path: ":categoryIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/casino/[categoryIdentity].vue")
  },
  {
    name: "casino",
    path: "",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/casino/index.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    meta: favorites_46clientlfal5o7O9bMeta || {},
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/winlion/pages/favorites.client.vue"))
  },
  {
    name: "games-id",
    path: "/games/:id()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/games/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/index.vue")
  },
  {
    name: live_45casinoNVgp3RHVNqMeta?.name,
    path: "/live-casino",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/live-casino.vue"),
    children: [
  {
    name: "live-casino-categoryIdentity",
    path: ":categoryIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/live-casino/[categoryIdentity].vue")
  },
  {
    name: "live-casino",
    path: "",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/live-casino/index.vue")
  }
]
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/loyalty.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profile_46clientIFkufzhE8pMeta || {},
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/winlion/pages/profile.client.vue")),
    children: [
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/documents.vue")
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/history.vue")
  },
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/limits.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/notifications.vue")
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/security.vue")
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/verification.vue")
  }
]
  },
  {
    name: "questions",
    path: "/questions",
    meta: questionsFxA7khpAxNMeta || {},
    component: () => import("/builds/platform/customer-frontends/winlion/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently",
    path: "/recently",
    meta: recently_46clientPRb44d3KbtMeta || {},
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/winlion/pages/recently.client.vue"))
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45up0WGB0WOSBrMeta || {},
    component: () => import("/builds/platform/customer-frontends/winlion/pages/sign-up.vue")
  },
  {
    name: "sports",
    path: "/sports",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/sports.vue")
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/static/[pageIdentity].vue")
  },
  {
    name: "tournaments-identity",
    path: "/tournaments/:identity()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/tournaments/[identity].vue")
  },
  {
    name: "tournaments",
    path: "/tournaments",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/tournaments/index.vue")
  }
]