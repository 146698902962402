<template>
  <component
    :is="props.url ? AtomicLink : 'button'"
    :class="componentClasses"
    :disabled="props.isDisabled || null"
    :href="props.url"
    :target-blank="props.targetBlank || null"
  >
    <div class="button-base__overlay-hover" />
    <div class="button-base__overlay" />

    <span class="button-base__content">
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
  const props = defineProps<{
    targetBlank?: boolean;
    type?:
      | 'primary'
      | 'secondary-bold-stroke'
      | 'secondary-1'
      | 'secondary-2'
      | 'secondary-3'
      | 'outlined'
      | 'outlined-icon';
    size?: 'xs' | 'sm' | 'md' | 'lg';
    isDisabled?: boolean;
    url?: string;
  }>();

  const AtomicLink = resolveComponent('atomic-link');

  const componentClasses = computed(() => [
    'button-base',
    props.type ? `button-base--${props.type}` : 'button-base--primary',
    props.size ? `button-base--size-${props.size}` : 'button-base--size-md',
    { 'is-disabled': props.isDisabled },
  ]);
</script>

<style src="~/assets/styles/components/button/base.scss" lang="scss" />
